import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
import searchFill from '@iconify/icons-eva/search-fill'
import { styled } from '@mui/material/styles'
import {
  Box,
  Toolbar,
  Typography,
  OutlinedInput,
  InputAdornment,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  padding: theme.spacing(0, 1, 0, 3)
}))

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 280,
  margin: theme.spacing(0, 2, 0, 0),
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}))

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterAddress: PropTypes.string,
  onFilterAddress: PropTypes.func,
  handleKeyDown: PropTypes.func,
  onFilterEmail: PropTypes.func,
  filterEmail: PropTypes.string,
  onFilterName: PropTypes.func,
  filterName: PropTypes.string,
  country: PropTypes.array,
  setSelectedCountry: PropTypes.func,
  selectedCountry: PropTypes.string
}

export default function UserListToolbar ({
  numSelected,
  filterAddress,
  onFilterAddress,
  handleKeyDown,
  filterEmail,
  onFilterEmail,
  filterName,
  onFilterName,
  country,
  setSelectedCountry,
  selectedCountry
}) {
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component='div' variant='subtitle1'>
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <SearchStyle
            value={filterAddress}
            onChange={onFilterAddress}
            onKeyDown={handleKeyDown}
            placeholder='Search by Wallet Address...'
            startAdornment={
              <InputAdornment position='start'>
                <Box
                  component={Icon}
                  icon={searchFill}
                  sx={{ color: 'text.disabled' }}
                />
              </InputAdornment>
            }
          />
          <SearchStyle
            value={filterEmail}
            onChange={onFilterEmail}
            onKeyDown={handleKeyDown}
            placeholder='Search by Email ...'
            startAdornment={
              <InputAdornment position='start'>
                <Box
                  component={Icon}
                  icon={searchFill}
                  sx={{ color: 'text.disabled' }}
                />
              </InputAdornment>
            }
          />
          <SearchStyle
            value={filterName}
            onChange={onFilterName}
            onKeyDown={handleKeyDown}
            placeholder='Search by User Name ...'
            startAdornment={
              <InputAdornment position='start'>
                <Box
                  component={Icon}
                  icon={searchFill}
                  sx={{ color: 'text.disabled' }}
                />
              </InputAdornment>
            }
          />
        </>
      )}
    </RootStyle>
  )
}
