// material
import { Box, Grid, Container, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
// components
import Page from '../components/Page'
import { AppVerifiedUsers } from '../components/_dashboard/app'
import { services } from '../services'

// ----------------------------------------------------------------------

export default function DashboardApp () {
  const [data, setData] = useState({
    totalUsers: 0,
    userCamefromTwitter: 0,
    userCamefromPassport: 0,
    usersUsingBoth: 0
  })

  useEffect(() => {
    async function getUsersCount () {
      const url = `${process.env.REACT_APP_BASE_URL}/api/v1/getuserstatistics`

      try {
        const getCount = await services.Get(url)

        if (getCount.status === 200) {
          const statistics = getCount.data // Directly access the data object
          console.log('Fetched statistics:', statistics) // Log the fetched data
          setData({
            totalUsers: statistics.totalUsers,
            userCamefromTwitter: statistics.userCamefromTwitter,
            userCamefromPassport: statistics.userCamefromPassport,
            usersUsingBoth: statistics.usersUsingBoth
          })
        }
      } catch (error) {
        console.error('Error fetching user statistics:', error)
      }
    }
    getUsersCount()
  }, [])

  return (
    <Page title='Dashboard | Ponchiqs'>
      <Container maxWidth='xl'>
        <Box sx={{ pb: 5 }}>
          <Typography variant='h4'>Welcome to Ponchiqs socialfi</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppVerifiedUsers name='Total Users' count={data.totalUsers} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppVerifiedUsers
              name='Users came from Twitter'
              count={data.userCamefromTwitter}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppVerifiedUsers
              name='Users came from Passport'
              count={data.userCamefromPassport}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppVerifiedUsers
              name='Users Using Both'
              count={data.usersUsingBoth}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}
