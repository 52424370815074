import React, { useEffect, useState } from 'react'
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  TableSortLabel,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Box,
  Menu,
  MenuItem,
  Switch
} from '@mui/material'
import Page from '../components/Page'
import Scrollbar from '../components/Scrollbar'
import SearchNotFound from '../components/SearchNotFound'
import { services } from '../services'

const TABLE_HEAD = [
  { id: 'taskName', label: 'Task Name', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'ponchPoints', label: 'Ponch Points', alignRight: false },
  { id: 'socialUrl', label: 'Social URL', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: true }
]

export default function Tasks () {
  const [tasks, setTasks] = useState([])
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('taskName')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [open, setOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [currentTask, setCurrentTask] = useState(null)
  const [taskToDelete, setTaskToDelete] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null) // For dropdown menu
  const [selectedTask, setSelectedTask] = useState(null) // For dropdown menu

  const getTasks = async () => {
    try {
      const response = await services.Get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/totaltasks`
      )
      if (response.status === 200) {
        setTasks(response.data.tasks)
      }
    } catch (error) {
      console.error('Error fetching tasks:', error)
    }
  }

  useEffect(() => {
    getTasks()
  }, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleOpen = (task = null) => {
    setCurrentTask(task)
    setOpen(true)
  }

  const handleClose = () => {
    setCurrentTask(null)
    setOpen(false)
  }

  const openDeleteDialog = task => {
    setTaskToDelete(task)
    setDeleteDialogOpen(true)
  }

  const closeDeleteDialog = () => {
    setTaskToDelete(null)
    setDeleteDialogOpen(false)
  }

  const handleDelete = async () => {
    try {
      await services.Delete(
        `${process.env.REACT_APP_BASE_URL}/api/v1/delete-task/${taskToDelete._id}`
      )
      getTasks()
      closeDeleteDialog()
    } catch (error) {
      console.error('Error deleting task:', error)
    }
  }

  const handleSave = async () => {
    try {
      if (currentTask._id) {
        await services.Put(
          `${process.env.REACT_APP_BASE_URL}/api/v1/edit-task/${currentTask._id}`,
          currentTask
        )
      } else {
        await services.Post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/create-task`,
          currentTask
        )
      }
      getTasks()
      handleClose()
    } catch (error) {
      console.error('Error saving task:', error)
    }
  }

  const handleToggleStatus = async (id, currentStatus) => {
    try {
      const newStatus = !currentStatus // Toggle the status
      await services.Put(
        `${process.env.REACT_APP_BASE_URL}/api/v1/edit-task/${id}`,
        {
          isdisabled: newStatus
        }
      )
      getTasks()
    } catch (error) {
      console.error('Error toggling task status:', error)
    }
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setCurrentTask({ ...currentTask, [name]: value })
  }

  // Functions for dropdown menu
  const handleClick = (event, task) => {
    setAnchorEl(event.currentTarget)
    setSelectedTask(task)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
    setSelectedTask(null)
  }

  const styles = {
    blurredDetail: {
      filter: 'blur(3px)',
      opacity: '0.5'
    },
    actionField: {
      display: 'flex',
      alignItems: 'center'
    }
  }

  return (
    <Page title='Tasks | Ponchiqs Socialfi'>
      <Container>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          mb={5}
        >
          <Typography variant='h4' gutterBottom>
            Task List
          </Typography>
          <Button
            variant='contained'
            color='primary'
            onClick={() => handleOpen()}
          >
            Add Task
          </Button>
        </Stack>

        <Card style={{ backgroundColor: '#151517' }}>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map(headCell => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? 'right' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{ color: '#FBAE48' }}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={event =>
                            handleRequestSort(event, headCell.id)
                          }
                          sx={{ color: '#FBAE48' }}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tasks
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(task => (
                      <TableRow hover key={task._id} tabIndex={-1}>
                        <TableCell>
                          <Box
                            style={task.isdisabled ? styles.blurredDetail : {}}
                          >
                            {task.taskName}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            style={task.isdisabled ? styles.blurredDetail : {}}
                          >
                            {task.description}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            style={task.isdisabled ? styles.blurredDetail : {}}
                          >
                            {task.ponchPoints}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            style={task.isdisabled ? styles.blurredDetail : {}}
                          >
                            {task.socialUrl}
                          </Box>
                        </TableCell>
                        <TableCell align='right'>
                          <Box style={styles.actionField}>
                            <Button
                              onClick={event => handleClick(event, task)}
                              disabled={task.isdisabled} // Disable actions button for disabled tasks
                            >
                              Actions
                            </Button>
                            <Menu
                              id='simple-menu'
                              anchorEl={anchorEl}
                              open={Boolean(
                                anchorEl &&
                                  selectedTask &&
                                  selectedTask._id === task._id
                              )}
                              onClose={handleCloseMenu}
                              PaperProps={{
                                style: {
                                  backgroundColor: '#000' // Change the color here
                                }
                              }}
                            >
                              <MenuItem
                                onClick={() => handleOpen(task)}
                                disabled={task.isdisabled} // Disable edit option for disabled tasks
                              >
                                Edit
                              </MenuItem>
                              <MenuItem
                                onClick={() => openDeleteDialog(task)}
                                disabled={task.isdisabled} // Disable delete option for disabled tasks
                              >
                                Delete
                              </MenuItem>
                            </Menu>
                            <Box ml={1}>
                              <Switch
                                checked={task.isdisabled}
                                onChange={() =>
                                  handleToggleStatus(task._id, task.isdisabled)
                                }
                                name='taskStatus'
                                color='primary'
                              />
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  {tasks.length === 0 && (
                    <TableRow>
                      <TableCell align='center' colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery='' />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={tasks.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{ style: { backgroundColor: '#000' } }}
        >
          <DialogTitle>{currentTask ? 'Edit Task' : 'Add Task'}</DialogTitle>
          <DialogContent>
            <TextField
              margin='dense'
              label='Task Name'
              fullWidth
              name='taskName'
              value={currentTask?.taskName || ''}
              onChange={handleInputChange}
            />
            <TextField
              margin='dense'
              label='Description'
              fullWidth
              name='description'
              value={currentTask?.description || ''}
              onChange={handleInputChange}
            />
            <TextField
              margin='dense'
              label='Ponch Points'
              fullWidth
              name='ponchPoints'
              type='number'
              value={currentTask?.ponchPoints || ''}
              onChange={handleInputChange}
            />
            <TextField
              margin='dense'
              label='Social URL'
              fullWidth
              name='socialUrl'
              value={currentTask?.socialUrl || ''}
              onChange={handleInputChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color='secondary'>
              Cancel
            </Button>
            <Button onClick={handleSave} color='primary'>
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={deleteDialogOpen}
          onClose={closeDeleteDialog}
          PaperProps={{ style: { backgroundColor: '#000' } }}
        >
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you want to delete the task "{taskToDelete?.taskName}"?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDeleteDialog} color='secondary'>
              Cancel
            </Button>
            <Button onClick={handleDelete} color='error'>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  )
}
