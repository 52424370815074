import React, { useState, useEffect } from 'react'
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  TableSortLabel,
  IconButton,
  TextField,
  Button,
  Paper,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Menu,
  MenuItem
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ClearIcon from '@mui/icons-material/Clear'
import Page from '../components/Page'
import Scrollbar from '../components/Scrollbar'
import { services } from '../services'

const TABLE_HEAD = [
  { id: 'twitterId', label: 'TwitterId', alignRight: false },
  { id: 'xUserName', label: 'Xusername', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'walletAddress', label: 'Wallet Address', alignRight: false },
  { id: 'web3WalletAddress', label: 'Web3 WalletAddress', alignRight: false },
  { id: 'socialPoints', label: 'Social Points', alignRight: false },
  { id: 'referralCount', label: 'Referral Count', alignRight: false },
  { id: 'referralPoints', label: 'Referral Points', alignRight: false },
  { id: 'ponchPoint', label: 'Ponch Point', alignRight: false },
  { id: 'ponchToken', label: 'PonchToken', alignRight: false }
]

const UserListToolbar = ({
  filterAddress,
  onFilterAddress,
  handleKeyDown,
  filterEmail,
  onFilterEmail,
  filterName,
  onFilterName
}) => {
  return (
    <Stack direction='row' spacing={2} style={{ padding: '20px' }}>
      <TextField
        label='Filter by Wallet Address'
        variant='outlined'
        size='small'
        value={filterAddress}
        onChange={onFilterAddress}
        onKeyDown={handleKeyDown}
      />
      <TextField
        label='Filter by Email'
        variant='outlined'
        size='small'
        value={filterEmail}
        onChange={onFilterEmail}
        onKeyDown={handleKeyDown}
      />
      <TextField
        label='Filter by Name'
        variant='outlined'
        size='small'
        value={filterName}
        onChange={onFilterName}
        onKeyDown={handleKeyDown}
      />
    </Stack>
  )
}

const EditUserForm = ({ user, onSubmit, onCancel }) => {
  const [web3WalletAddress, setWeb3WalletAddress] = useState(
    user.web3WalletAddress
  )

  const handleChange = e => {
    const { value } = e.target
    setWeb3WalletAddress(value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    onSubmit(user.id, { web3WalletAddress })
  }

  return (
    <Paper elevation={3} style={{ padding: 20, background: '#000' }}>
      <Typography variant='h5' gutterBottom>
        Edit User
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} style={{ marginTop: '20px' }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Web3 Wallet Address'
              name='web3WalletAddress'
              value={web3WalletAddress}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          justifyContent='flex-end'
          style={{ marginTop: 20 }}
        >
          <Grid item>
            <Button variant='contained' onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button type='submit' variant='contained' color='primary'>
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  )
}

export default function User () {
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [walletAddress, setWalletAddress] = useState('')
  const [email, setEmail] = useState('')
  const [filterName, setFilterName] = useState('')
  const [users, setUsers] = useState([])
  const [pageNo, setPageNo] = useState(1)
  const [editingUser, setEditingUser] = useState(null)
  const [showDetailsDialog, setShowDetailsDialog] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState(null)
  const [userTasks, setUserTasks] = useState([])
  const [comments, setComments] = useState({})
  const [anchorEl, setAnchorEl] = useState(null)
  const [currentRow, setCurrentRow] = useState(null)
  const [pagination, setPagination] = useState({
    limit: 20,
    pageNo: 1,
    totalPages: 0,
    totalRecords: 0
  })

  const getUserList = async pageNo => {
    let url = `${process.env.REACT_APP_BASE_URL}/api/v1/getallusers?page=${pageNo}`
    if (walletAddress) {
      url += `&address=${walletAddress}`
    }
    if (email) {
      url += `&email=${email}`
    }
    if (filterName) {
      url += `&name=${filterName}`
    }

    try {
      const response = await services.Get(url)
      console.log(response)

      if (response.status === 200) {
        const responseData = response.data || {}
        const list = responseData.users || []

        if (Array.isArray(list)) {
          const transformedList = list.map(user => ({
            id: user._id,
            walletAddress: user.walletAddress,
            web3WalletAddress: user.web3WalletAddress,
            xUserName: user.xUserName,
            twitterId: user.twitterId,
            email: user.email,
            socialPoints: user.socialPoints,
            referralCount: user.referralCount,
            referralPoints: user.referralPonchPoints,
            ponchPoint: user.totalPonchPoints,
            ponchToken: user.ponchTokens
          }))

          setUsers(transformedList)
          setPagination(responseData.pagination || pagination)
        } else {
          console.error('Error: User list is not an array')
        }
      }
    } catch (error) {
      console.error('Error fetching user list:', error)
    }
  }

  useEffect(() => {
    getUserList(pageNo)
  }, [pageNo, walletAddress, email, filterName])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage + 1)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleFilterByAddress = event => {
    setWalletAddress(event.target.value)
    if (email) setEmail('')
    if (filterName) setFilterName('')
  }

  const handleFilterByEmail = event => {
    setEmail(event.target.value)
    if (walletAddress) setWalletAddress('')
    if (filterName) setFilterName('')
  }

  const handleFilterByName = event => {
    setFilterName(event.target.value)
    if (walletAddress) setWalletAddress('')
    if (email) setEmail('')
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      getUserList(1)
    }
  }

  const handleEditClick = user => {
    setEditingUser(user)
  }

  const handleEditSubmit = async (userId, updatedUserData) => {
    try {
      const response = await services.Put(
        `${process.env.REACT_APP_BASE_URL}/api/v1/update-user/${userId}`,
        updatedUserData
      )
      if (response.status === 200) {
        const updatedUsers = users.map(user =>
          user.id === userId ? { ...user, ...updatedUserData } : user
        )
        setUsers(updatedUsers)
        setEditingUser(null)
      } else {
        console.error('Edit failed:', response.data.message)
      }
    } catch (error) {
      console.error('Error editing user:', error)
    }
  }

  const handleViewDetails = async userId => {
    try {
      const response = await services.Get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/getusertasks/${userId}`
      )
      if (response.status === 200) {
        setUserTasks(response.data.tasks)
        setShowDetailsDialog(true)
      } else {
        console.error('Failed to fetch user tasks:', response.data.message)
      }
    } catch (error) {
      console.error('Error fetching user tasks:', error)
    }
  }

  const handleCloseDetailsDialog = () => {
    setShowDetailsDialog(false)
  }

  const handleTaskAction = async (action, taskHistoryId) => {
    const comment = comments[taskHistoryId] || ''
    const approvedBy = localStorage.getItem('role') || 'Unknown'
    try {
      const response = await services.Post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/task-Assessment/${taskHistoryId}`,
        { action, Comment: comment }
      )
      if (response.status === 200) {
        const updatedTasks = userTasks.map(task =>
          task.taskHistoryid === taskHistoryId
            ? {
                ...task,
                status: action === 'approve' ? 'approved' : 'rejected',
                approvedBy
              }
            : task
        )
        setUserTasks(updatedTasks)
        setComments(prevComments => ({ ...prevComments, [taskHistoryId]: '' }))
      } else {
        console.error(`${action} action failed:`, response.data.message)
      }
    } catch (error) {
      console.error(`Error ${action}ing task:`, error)
    }
  }

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget)
    setCurrentRow(row)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    setCurrentRow(null)
  }

  // Function to shorten wallet address
  const shortenWalletAddress = address => {
    // Check if address is valid
    if (!address || address.length < 10) {
      return ''
    }
    // Shorten the address by showing only the first and last few characters
    return `${address.substring(0, 6)}...${address.substring(
      address.length - 4
    )}`
  }

  return (
    <Page title='User | Ponchiqs'>
      <Container>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          mb={5}
        >
          <Typography variant='h4' gutterBottom>
            Ponchiqs Socialfi Registered User List
          </Typography>
        </Stack>

        <Card style={{ backgroundColor: '#151517' }}>
          <UserListToolbar
            filterAddress={walletAddress}
            onFilterAddress={handleFilterByAddress}
            handleKeyDown={handleKeyDown}
            filterEmail={email}
            onFilterEmail={handleFilterByEmail}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map(headCell => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? 'right' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{ color: '#FBAE48' }}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={event =>
                            handleRequestSort(event, headCell.id)
                          }
                          sx={{ color: '#FBAE48' }}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell align='right' sx={{ color: '#FBAE48' }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users
                    .filter(row => {
                      // Apply filters here
                      if (
                        walletAddress &&
                        !row.walletAddress.includes(walletAddress)
                      ) {
                        return false
                      }
                      if (email && !row.email.includes(email)) {
                        return false
                      }
                      if (filterName && !row.xUserName.includes(filterName)) {
                        return false
                      }
                      // If no filters are applied or the row matches all filters, return true
                      return true
                    })
                    .map(row => {
                      const {
                        id,
                        walletAddress,
                        web3WalletAddress,
                        xUserName,
                        twitterId,
                        email,
                        socialPoints,
                        referralCount,
                        referralPoints,
                        ponchPoint,
                        ponchToken
                      } = row
                      return (
                        <TableRow hover key={id} tabIndex={-1}>
                          <TableCell spacing={2}>{twitterId}</TableCell>
                          <TableCell component='th' scope='row' padding='none'>
                            <Stack
                              direction='row'
                              alignItems='center'
                              spacing={2}
                            >
                              <Typography variant='subtitle2' noWrap>
                                {xUserName}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align='left'>{email}</TableCell>
                          <TableCell align='left'>
                            {shortenWalletAddress(walletAddress)}
                          </TableCell>
                          <TableCell align='left'>
                            {shortenWalletAddress(web3WalletAddress)}
                          </TableCell>
                          <TableCell align='left'>{socialPoints}</TableCell>
                          <TableCell align='left'>{referralCount}</TableCell>
                          <TableCell align='left'>{referralPoints}</TableCell>
                          <TableCell align='left'>{ponchPoint}</TableCell>
                          <TableCell align='left'>{ponchToken}</TableCell>
                          <TableCell align='right'>
                            <IconButton
                              onClick={event => handleMenuOpen(event, row)}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl) && currentRow === row}
                              onClose={handleMenuClose}
                              PaperProps={{
                                sx: { backgroundColor: '#000' } // Set background color of the paper
                              }}
                            >
                              {/* //todo: edit wallet address functionality .....right now its not needed. */}
                              {/* <MenuItem
                                onClick={() => {
                                  handleEditClick(row)
                                  handleMenuClose()
                                }}
                              >
                                <EditIcon />
                                Edit
                              </MenuItem> */}
                              <MenuItem
                                onClick={() => {
                                  handleViewDetails(id)
                                  handleMenuClose()
                                }}
                              >
                                <VisibilityIcon />
                                View Details
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  {users.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={TABLE_HEAD.length + 1} align='center'>
                        No users found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component='div'
              count={pagination.totalRecords}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Scrollbar>
        </Card>

        {editingUser && (
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '0',
              width: '600px'
            }}
          >
            <EditUserForm
              user={editingUser}
              onSubmit={handleEditSubmit}
              onCancel={() => setEditingUser(null)}
            />
          </div>
        )}

        <Dialog
          open={showDetailsDialog}
          onClose={handleCloseDetailsDialog}
          fullWidth
          maxWidth='md'
          style={{ background: '#000' }}
        >
          {/* <DialogTitle style={{ background: '#000', fontSize: '20px' }}>
            User Tasks
          </DialogTitle> */}
          <DialogTitle style={{ background: '#000', fontSize: '20px' }}>
            User Tasks
            <IconButton
              aria-label='close'
              onClick={handleCloseDetailsDialog}
              style={{
                position: 'absolute',
                right: '10px',
                top: '10px',
                color: '#fff'
              }}
            >
              <ClearIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ background: '#000' }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ color: '#FBAE48' }}>
                      Task Name
                    </TableCell>
                    <TableCell style={{ color: '#FBAE48' }}>Status</TableCell>
                    <TableCell style={{ color: '#FBAE48' }}>
                      Approved By
                    </TableCell>
                    <TableCell style={{ color: '#FBAE48' }}>Comment</TableCell>
                    <TableCell style={{ color: '#FBAE48' }}>
                      ApproveAction
                    </TableCell>
                    <TableCell style={{ color: '#FBAE48' }}>
                      RejectAction
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userTasks.map(task => (
                    <TableRow key={task.taskHistoryid}>
                      <TableCell>{task.taskName}</TableCell>
                      <TableCell>{task.status}</TableCell>
                      <TableCell>{task.approvedBy}</TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          label='Comment'
                          value={comments[task.taskHistoryid] || ''}
                          onChange={e =>
                            setComments({
                              ...comments,
                              [task.taskHistoryid]: e.target.value
                            })
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={() =>
                            handleTaskAction('approve', task.taskHistoryid)
                          }
                        >
                          Approve
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant='contained'
                          color='secondary'
                          onClick={() =>
                            handleTaskAction('reject', task.taskHistoryid)
                          }
                        >
                          Reject
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </Dialog>
      </Container>
    </Page>
  )
}
