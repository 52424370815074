import { Link as RouterLink } from 'react-router-dom'
import { useEffect } from 'react'
// material
import { styled } from '@mui/material/styles'
import { Card, Stack, Link, Container, Typography } from '@mui/material'
// components
import Page from '../components/Page'
import { MHidden } from '../components/@material-extend'
import { LoginForm } from '../components/authentication/login'
import AuthSocial from '../components/authentication/AuthSocial'
import LArt from '../assets/illustration_login.png'
import { useNavigate } from 'react-router-dom'

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}))

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
  backgroundColor: '#e1e9fe'
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}))

// ----------------------------------------------------------------------

export default function Login () {
  const navigate = useNavigate()
  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate('/dashboard', { replace: true })
    }
  })

  return (
    <RootStyle title='Login | Ponchiqs' sx={{ backgroundColor: '#030303' }}>
      <MHidden width='mdDown'>
        <SectionStyle sx={{ backgroundColor: '#030303' }}>
          <Typography
            variant='h3'
            sx={{ px: 5, mt: 10, mb: 5, color: 'white' }}
          >
            Welcome to Admin panel
          </Typography>
          <img src={LArt} alt='login' />
        </SectionStyle>
      </MHidden>

      <Container maxWidth='sm'>
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant='h4' gutterBottom>
              Sign in to ponchiqs Admin
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Enter your details below.
            </Typography>
          </Stack>
          {/* <AuthSocial /> */}

          <LoginForm />

          <MHidden width='smUp'>
            <Typography variant='body2' align='center' sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              <Link variant='subtitle2' component={RouterLink} to='register'>
                Get started
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  )
}
