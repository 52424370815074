import React, { useEffect, useState } from 'react'
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  TableSortLabel,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Box,
  Menu,
  MenuItem
} from '@mui/material'
import Page from '../components/Page'
import Scrollbar from '../components/Scrollbar'
import SearchNotFound from '../components/SearchNotFound'
import { services } from '../services'

const TABLE_HEAD = [
  { id: 'tierName', label: 'Tier Name', alignRight: false },
  { id: 'ponchPoints', label: 'Ponch Points', alignRight: false },
  { id: 'bonus', label: 'Bonus', alignRight: false }
    /* //todo: this is importent code dont remove ......right now its not used. */
  // { id: 'actions', label: 'Actions', alignRight: true }
]

export default function Tiers () {
  const [tiers, setTiers] = useState([])
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('tierName')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [open, setOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [currentTier, setCurrentTier] = useState(null)
  const [tierToDelete, setTierToDelete] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedTier, setSelectedTier] = useState(null)

  const getTiers = async () => {
    try {
      const response = await services.Get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get-tiers`
      )
      if (response.status === 200) {
        setTiers(response.data.tiers)
      }
    } catch (error) {
      console.error('Error fetching tiers:', error)
    }
  }

  useEffect(() => {
    getTiers()
  }, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleOpen = (tier = null) => {
    setCurrentTier(tier)
    setOpen(true)
  }

  const handleClose = () => {
    setCurrentTier(null)
    setOpen(false)
  }

  const openDeleteDialog = tier => {
    setTierToDelete(tier)
    setDeleteDialogOpen(true)
  }

  const closeDeleteDialog = () => {
    setTierToDelete(null)
    setDeleteDialogOpen(false)
  }

  const handleDelete = async () => {
    try {
      await services.Delete(
        `${process.env.REACT_APP_BASE_URL}/api/v1/tiers/${tierToDelete._id}`
      )
      getTiers()
      closeDeleteDialog()
    } catch (error) {
      console.error('Error deleting tier:', error)
    }
  }

  const handleSave = async () => {
    try {
      if (currentTier._id) {
        await services.Put(
          `${process.env.REACT_APP_BASE_URL}/api/v1/tiers/${currentTier._id}`,
          currentTier
        )
      } else {
        await services.Post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/add-tier`,
          currentTier
        )
      }
      getTiers()
      handleClose()
    } catch (error) {
      console.error('Error saving tier:', error)
    }
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setCurrentTier({ ...currentTier, [name]: value })
  }

  const handleClick = (event, tier) => {
    setAnchorEl(event.currentTarget)
    setSelectedTier(tier)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
    setSelectedTier(null)
  }

  const handleDisable = async id => {
    try {
      await services.Put(`${process.env.REACT_APP_BASE_URL}/api/v1/tiers/${id}`, {
        disabled: true
      })
      getTiers()
    } catch (error) {
      console.error('Error disabling tier:', error)
    }
  }

  return (
    <Page title='Tiers | Ponchiqs Socialfi'>
      <Container>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          mb={5}
        >
          <Typography variant='h4' gutterBottom>
            Tier List
          </Typography>
          {/* //todo: this is importent code dont remove ......right now its not used. */}
          {/* <Button
            variant='contained'
            color='primary'
            onClick={() => handleOpen()}
          >
            Add Tier
          </Button> */}
        </Stack>

        <Card style={{ backgroundColor: '#151517' }}>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map(headCell => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? 'right' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{ color: '#FBAE48' }}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={event =>
                            handleRequestSort(event, headCell.id)
                          }
                          sx={{ color: '#FBAE48' }}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tiers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(tier => (
                      <TableRow hover key={tier._id} tabIndex={-1}>
                        <TableCell>{tier.tierName}</TableCell>
                        <TableCell>{tier.ponchPoints}</TableCell>
                        <TableCell>{tier.bonus}</TableCell>
                        {/* //todo: this is importent code .....right now its not used. */}
                        {/* <TableCell align='right'>
                          <Button onClick={event => handleClick(event, tier)}>
                            Actions
                          </Button>
                          <Menu
                            id='simple-menu'
                            anchorEl={anchorEl}
                            open={Boolean(
                              anchorEl &&
                                selectedTier &&
                                selectedTier._id === tier._id
                            )}
                            onClose={handleCloseMenu}
                            PaperProps={{
                              style: {
                                backgroundColor: '#000' // Change the color here
                              }
                            }}
                          >
                            <MenuItem onClick={() => handleOpen(tier)}>
                              Edit
                            </MenuItem>
                            <MenuItem onClick={() => openDeleteDialog(tier)}>
                              Delete
                            </MenuItem>
                            <MenuItem
                              onClick={() => handleDisable(tier._id)}
                              disabled={tier.disabled}
                            >
                              {tier.disabled ? 'Disabled' : 'Disable'}
                            </MenuItem>
                          </Menu>
                        </TableCell> */}
                      </TableRow>
                    ))}
                  {tiers.length === 0 && (
                    <TableRow>
                      <TableCell align='center' colSpan={4} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery='' />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={tiers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{ style: { backgroundColor: '#000' } }}
        >
          <DialogTitle>{currentTier ? 'Edit Tier' : 'Add Tier'}</DialogTitle>
          <DialogContent>
            <TextField
              margin='dense'
              label='Tier Name'
              fullWidth
              name='tierName'
              value={currentTier?.tierName || ''}
              onChange={handleInputChange}
            />
            <TextField
              margin='dense'
              label='Ponch Points'
              fullWidth
              name='ponchPoints'
              type='number'
              value={currentTier?.ponchPoints || ''}
              onChange={handleInputChange}
            />
            <TextField
              margin='dense'
              label='Bonus'
              fullWidth
              name='bonus'
              type='number'
              value={currentTier?.bonus || ''}
              onChange={handleInputChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color='secondary'>
              Cancel
            </Button>
            <Button onClick={handleSave} color='primary'>
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={deleteDialogOpen}
          onClose={closeDeleteDialog}
          PaperProps={{ style: { backgroundColor: '#000' } }}
        >
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you want to delete the tier "{tierToDelete?.tierName}"?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDeleteDialog} color='secondary'>
              Cancel
            </Button>
            <Button onClick={handleDelete} color='error'>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  )
}
