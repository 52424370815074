import getContractAddresses from './addresses';
import Web3 from 'web3';
import { web3 } from '../web3';
// import claim from "../contractData/abis/claim.json";
import claim from '../abi/claim.json';
import feStakingABI from '../abi/feStaking.json';
import farmingABI from '../abi/farming.json';
import tokenAbi from '../abi/token.json';
import lpTokenABI from '../abi/lpToken.json';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export function TimeStampToDateString(val) {
  const dateString = new Date(Number(val) * 1000);
  var year = dateString.getUTCFullYear();
  // month as 2 digits (MM)
  var month = dateString.getUTCMonth();
  // date as 2 digits (DD)
  var date = ('0' + dateString.getUTCDate()).slice(-2);
  var hrs = ('0' + dateString.getUTCHours()).slice(-2);
  var min = ('0' + dateString.getUTCMinutes()).slice(-2);
  var sec = ('0' + dateString.getUTCSeconds()).slice(-2);
  let d = `${year}-${months[month]}-${date} ${hrs}:${min}:${sec} UTC`;
  return d;
}
export function utcToLocalTimeStamp(timestamp) {
  const date = new Date(timestamp);
  var newTimeStamp = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
  
  return newTimeStamp;
}

export function ordinal_suffix_of(i) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + 'st';
  }
  if (j == 2 && k != 12) {
    return i + 'nd';
  }
  if (j == 3 && k != 13) {
    return i + 'rd';
  }
  return i + 'th';
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function compactAddress(address) {
  const newAddress = address;
  if (address) {
    const res =
      newAddress.substring(0, 4) +
      '...' +
      newAddress.substring(newAddress.length - 4, newAddress.length);
    return res;
  }
}

export function getContractInstance(contract, address) {
  // const addresses = getContractAddresses[contract];
  // let currentaddress = getContractAddresses[contract];
  let currentABI = contract === 'seedifyToken' ? tokenAbi : feStakingABI;
  if (contract === 'Farming') {
    currentABI = farmingABI;
  }
  if (contract === 'lpToken') {
    currentABI = lpTokenABI;
    // currentaddress = addresses.farmingContract.lpToken;
  }
  // if (contract === "pancakeLP") {
  //   currentABI = pancakeLPABI;
  //   currentaddress = addresses.pancakeLP;
  // }

  try {
    if (web3) {
      const contractInstance = new web3.eth.Contract(currentABI, address);
      return contractInstance;
    }
  } catch (error) {
    // console.log(error);
  }
}

export const networkProviders = {
  binance: new Web3(
    new Web3.providers.HttpProvider(
      // 'https://data-seed-prebsc-1-s1.binance.org:8545/'
      'https://rpc.ankr.com/bsc_testnet_chapel'
    )
  ),
  polygon: new Web3('https://rpc.ankr.com/polygon_mumbai'),
  ethereum: new Web3(
    new Web3.providers.HttpProvider(
      // 'https://rinkeby.infura.io/v3/bef04cc587f548c8b1a3e76780722345'
      'https://rpc.ankr.com/eth_goerli'
    )
  ),
  avalanche: new Web3(
    new Web3.providers.HttpProvider(
      'https://api.avax-test.network/ext/bc/C/rpc'
    )
  ),
};
